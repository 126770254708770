import { getApi } from "@/store/api/api";
import { Action } from "@/store/actionType";
import { RuleViolationTrend } from "@/store/models/adminGeneral";
import { RuleViolationTrendCsv } from "@/store/models/registration/registration";

export default new (class RuleViolationTrendAPI {
  public async getRuleViolationTrend(query = "") {
    const action = Action.getRuleViolationTrend + query;
    const response = await getApi(action);
    return response as RuleViolationTrend;
  }

  public async getRuleViolationTrendCsv(query = "") {
    const action = Action.getRuleViolationTrendCsv + query;
    const response = await getApi(action);
    return response;
  }
})();
