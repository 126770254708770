












import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Highcharts from "highcharts";
import loadExporting from "highcharts/modules/exporting";
@Component
export default class BarChart extends Vue {
  @Prop()
  elId!: string;

  @Prop()
  chartTitle!: string;
  @Prop()
  chartType!: string;

  @Prop()
  categories!: string[];
  @Prop()
  graphData!: number[];

  @Prop()
  selectedYear!: number;

  colors: string[] = [];
  mounted() {
    this.renderBarChart();
  }

  renderBarChart() {
    var that = this;
    const series = [
      {
        pointWidth: 20,
        name: "Number of Incident",
        showInLegend: false,
        data: that.graphData,
      },
    ];
    loadExporting(Highcharts);
    Highcharts.chart(that.elId, {
      title: {
        text: "Rule Violation Trend",
        margin: 50,
      },
      credits: {
        enabled: false
      },
      yAxis: {
        title: {
          text: "Numbers",
        },
      },

      xAxis: {
        // accessibility: {
        //   rangeDescription: "Range: 2010 to 2017",
        // },
        type: "datetime",
        labels: {
          format: '{value: %b}'
        },
        title: {
          text: "Months in AD",
        }
      },

      legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "middle",
      },

      plotOptions: {
        // series: {
        //   label: {
        //     connectorAllowed: false,
        //   },
        //   pointStart: 2021,
        // },
        series: {
          pointStart: Date.UTC(that.selectedYear, 0, 1),
          pointIntervalUnit: "month",
        },
      },

      series: this.graphData as any[],

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom",
              },
            },
          },
        ],
      },
      // chart: {
      //   type: that.chartType,
      // },
      // exporting: {
      //   enabled: true,
      // },
      // title: {
      //   text: that.chartTitle,
      // },

      // xAxis: {
      //   categories: that.categories,
      //   title: {
      //     text: null,
      //   },
      // },
      // yAxis: {
      //   min: 0,
      //   allowDecimals: false,
      //   title: {
      //     text: "Number of Incidents",
      //     // align: "high"
      //   },
      //   labels: {
      //     overflow: "justify",
      //   },
      // },

      // tooltip: {
      //   valueSuffix: "",
      // },

      // plotOptions: {
      //   bar: {
      //     dataLabels: {
      //       enabled: true,
      //     },
      //   },
      // },
      // legend: {
      //   layout: "vertical",
      //   align: "right",
      //   verticalAlign: "top",
      //   x: -40,
      //   y: 80,
      //   floating: true,
      //   borderWidth: 1,

      //   shadow: true,
      // },
      // series: series as any[],
      // responsive: {
      //   rules: [
      //     {
      //       condition: {
      //         maxWidth: 100 + "%",
      //       },
      //       chartOptions: {
      //         legend: {
      //           align: "center",
      //           verticalAlign: "bottom",
      //           layout: "horizontal",
      //         },
      //         yAxis: {
      //           labels: {
      //             align: "left",
      //             x: 0,
      //             y: -5,
      //           },
      //           title: {
      //             text: null,
      //           },
      //         },
      //         subtitle: {
      //           text: null,
      //         },
      //         credits: {
      //           enabled: false,
      //         },
      //       },
      //     },
      //   ],
      // } as any,
      // credits: {
      //   enabled: false,
      // },
    });
  }
  @Watch("graphData")
  onlabelsChanged(val: string, oldVal: string) {
    this.renderBarChart();
  }
  radomColor() {
    return "#" + ((Math.random() * 0xffffff) << 0).toString(16);
  }
}
