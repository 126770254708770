import {
  VuexModule,
  Module,
  getModule,
  Mutation,
  Action,
  MutationAction,
} from "vuex-module-decorators";
import {
  RuleViolationTrend,
  RuleViolationTrendSearch,
} from "@/store/models/adminGeneral";
import { RuleViolationTrendCsv } from "@/store/models/registration/registration";
import api from "@/store/api/adminGeneral";
import store from "@/store";

@Module({
  namespaced: true,
  name: "adminGeneral",
  store,
  dynamic: true,
})
class AdminGeneralModule extends VuexModule {
  ruleViolationTrend: RuleViolationTrend = {} as RuleViolationTrend;
  ruleViolationTrendCsv: RuleViolationTrendCsv[] = [];

  @MutationAction
  async getTrend(params: RuleViolationTrendSearch) {
    let query = "";

    if (params) {
      query = "?";
      if (params.year) {
        query += `&year=${params.year}`;
      }
      if (params.transportCompany) {
        query += `&transport_company=${params.transportCompany}`;
      }
      if (params.ruleViolation) {
        query += `&rule_violation=${params.ruleViolation}`;
      }
      if (params.vehicleId) {
        query += `&vehicle_id=${params.vehicleId}`;
      }
    }
    const data = await api.getRuleViolationTrend(query);
    return { ruleViolationTrend: data as RuleViolationTrend };
  }

  @Action
  async getTrendCsv(params: RuleViolationTrendSearch) {
    let query = "";

    if (params) {
      query = "?";
      if (params.year) {
        query += `&year=${params.year}`;
      }
      if (params.vehicleId) {
        query += `&vehicle_id=${params.vehicleId}`;
      }
      if (params.ruleViolation) {
        query += `&rule_violation=${params.ruleViolation}`;
      }
    }

    const data = await api.getRuleViolationTrendCsv(query);
    return data;
  }
}
export default getModule(AdminGeneralModule);
