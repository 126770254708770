























































































































































import { Vue, Component } from "vue-property-decorator";
import AdminCards from "../admin/subComponents/AdminCards.vue";
import AdminPie from "../admin/subComponents/AdminPie.vue";
import AdminFilters from "../../components/UIComponents/Admin/Filters.vue";
import AdminBarcharts from "../admin/subComponents/AdminBarcharts.vue";
import metaStore from "@/store/modules/meta";
import { VehicleOwnerList } from "@/store/models/meta";
import { RuleViolationTrendSearch } from "@/store/models/adminGeneral";
import LineChart from "@/views/admin/subComponents/LineChart.vue";
import ruleViolationTrend from "@/store/modules/adminGeneral";
import vehicle from "@/store/modules/vehicle";
import { VehicleListForRuleViolationCsv } from "@/store/models/meta";
import {
  RuleViolationTrendCsv,
  VehicleListFilterModel,
} from "@/store/models/registration/registration";
import helpers from "@/utils/helpers";
import { getApi } from "@/store/api/api";

@Component({
  components: {
    AdminFilters,
    AdminCards,
    AdminPie,
    AdminBarcharts,
    LineChart,
  },
})
export default class Admin extends Vue {
  options: Array<{ value: number; label: number }> = [];
  transport_company_list: VehicleOwnerList[] = [];
  ruleViolationList = [
    {
      label: "All",
    },
    {
      value: 1,
      label: "Route Violation",
    },
    {
      value: 2,
      label: "Speed Violation",
    },
  ];
  vehicleNumberList: any[] = [];

  filter: RuleViolationTrendSearch = {} as RuleViolationTrendSearch;
  vehicleNumber: number | null = null;

  async created() {
    for (
      let i = new Date().getFullYear() - 4;
      i <= new Date().getFullYear();
      i++
    ) {
      const data = {
        value: i,
        label: i,
      };
      this.options.unshift(data);
    }
    this.filter.year = this.options[0].value;
    await ruleViolationTrend.getTrend(this.filter);

    const user = helpers.getUserProfile();
    if (user && user.userType === "gps_service_provider") {
      let params: VehicleListFilterModel = {
        gps_service_provider: user.id,
        approvalStatus: "Approved",
      } as VehicleListFilterModel;

      await vehicle.loadAllVehicle(params);

      let tc_list = vehicle.vehicleOnlyList;
      for (let i of tc_list) {
        let obj: VehicleOwnerList = {} as VehicleOwnerList;
        obj.id = i.vehicleOwner;
        obj.name = i.vehicleOwnerCompanyName;
        this.transport_company_list.push(obj);
      }
      const filteredArray = this.transport_company_list.filter(
        (obj, index, arr) => {
          return arr.map((mapObj) => mapObj.id).indexOf(obj.id) === index;
        }
      );

      this.transport_company_list = filteredArray;
    } else if (user && user.userType === "vehicle_owner") {
      let obj: VehicleOwnerList = {} as VehicleOwnerList;
      obj.id = user.id;
      obj.name = user.userName;
      this.transport_company_list = [obj];
    } else {
      await metaStore.loadAllVehicleOwner();
      await metaStore.loadVehicleListDD();
      this.transport_company_list = metaStore.vehicleOwnerList;
      this.vehicleNumberList = metaStore.vehicleList;
    }
  }

  async onYearChange(e: any) {
    this.filter.year = e;
    await ruleViolationTrend.getTrend(this.filter);
  }

  async onTCChange(e: any) {
    this.filter.transportCompany = e;
    this.vehicleNumberList = [];
    await ruleViolationTrend.getTrend(this.filter);
    if (e) {
      await vehicle.getVehicleForRuleViolation(this.filter);
      this.vehicleNumberList = vehicle.vehicleForRuleViolationCsv;
    } else {
      await metaStore.loadVehicleListDD();
      this.vehicleNumberList = vehicle.vehicleForRuleViolationCsv;
    }
    this.vehicleNumber = null;
  }

  async onRuleViolationChange() {
    await ruleViolationTrend.getTrend(this.filter);
  }

  async onVehicleChange(e: any) {
    this.filter.vehicleId = e;
    await ruleViolationTrend.getTrend(this.filter);
  }

  get selectedYear() {
    return this.filter.year;
  }

  get incidentReportLabelByMonthly() {
    return "Test";
  }

  get incidentReportDataByMonthly() {
    return [
      {
        name: "Total Registered Vehicles",
        data:
          ruleViolationTrend.ruleViolationTrend.totalRegisteredVehicleByMonth,
      },
      {
        name: "Total Rule Violation",
        data: ruleViolationTrend.ruleViolationTrend.ruleViolationByMonth,
      },
      {
        name: "Speed Violation",
        data: ruleViolationTrend.ruleViolationTrend.speedViolationByMonth,
      },
      {
        name: "Route Violation",
        data: ruleViolationTrend.ruleViolationTrend.routeViolationByMonth,
      },
    ];
  }

  async downloadExcel(e: any) {
    const sheet_path = await ruleViolationTrend.getTrendCsv(this.filter);
    const link = document.createElement("a");
    link.setAttribute("href", sheet_path.excelSheetPath);
    link.click();
  }
}
